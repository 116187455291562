import React, { useEffect, useState } from "react";
import axios from "axios";
import { AiOutlineNumber } from "react-icons/ai";
const AdminDashboard = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    axios.get("/admin/dashboard").then((res) => {
      setData(res);
    });
  }, []);

  return (
    <div className="pcoded-main-container">
      <div className="pcoded-wrapper">
        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="row">
                  <div className="col-md-6 col-xl-4">
                    <div className="card daily-sales">
                      <div className="card-block">
                        <h5 className="mb-4">Total Users</h5>
                        <div className="row d-flex align-items-center">
                          <div className="col-9 d-flex align-items-center">
                            <AiOutlineNumber
                              size={30}
                              className="text-gray mr-2 align-items-center"
                            />
                            <h3 className="f-w-300 align-items-center">
                              <b> {data?.users} </b>
                            </h3>
                          </div>
                        </div>
                        <div
                          className="progress m-t-30"
                          style={{ height: "7px" }}
                        >
                          <div
                            className="progress-bar progress-c-theme"
                            role="progressbar"
                            style={{ width: "100%" }}
                            aria-valuenow="50"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-xl-4">
                    <div className="card Monthly-sales">
                      <div className="card-block">
                        <h5 className="mb-4">New Requests</h5>
                        <div className="row d-flex align-items-center">
                          <div className="col-9 d-flex align-items-center">
                            <AiOutlineNumber
                              size={30}
                              className="text-gray mr-2 align-items-center"
                            />
                            <h3 className="f-w-300 align-items-center">
                              <b> {data?.requests} </b>
                            </h3>
                          </div>
                        </div>
                        <div
                          className="progress m-t-30"
                          style={{ height: "7px" }}
                        >
                          <div
                            className="progress-bar progress-c-theme2"
                            role="progressbar"
                            style={{ width: "100%" }}
                            aria-valuenow="35"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-xl-4">
                    <div className="card Monthly-sales">
                      <div className="card-block">
                        <h5 className="mb-4">Schedule Tasks</h5>
                        <div className="row d-flex align-items-center">
                          <div className="col-9 d-flex align-items-center">
                            <AiOutlineNumber
                              size={30}
                              className="text-gray mr-2 align-items-center"
                            />
                            <h3 className="f-w-300 align-items-center">
                              <b> {data?.schedules}</b>
                            </h3>
                          </div>
                        </div>
                        <div
                          className="progress m-t-30"
                          style={{ height: "7px" }}
                        >
                          <div
                            className="progress-bar progress-c-theme"
                            role="progressbar"
                            style={{ width: "100%" }}
                            aria-valuenow="70"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-lg-8">
                    <h5>Orders</h5>

                    <ChartLine />
                  </div> */}

                  {/* <div className="col-xl-4 col-md-6">
                    <div className="card card-event">
                      <div className="card-block">
                        <div className="row align-items-center justify-content-center">
                          <div className="col">
                            <h5 className="m-0">TOTAL ORDERS</h5>
                          </div>
                        </div>
                        <div className="d-flex">
                          <AiOutlineNumber
                            size={40}
                            className="text-gray mr-2 align-items-center mt-4"
                          />
                          <h2 className="mt-3 f-w-300">{data?.totalOrders}</h2>
                        </div>
                        <i className="fab fa-angellist text-c-purple f-50"></i>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-block border-bottom">
                        <div className="row d-flex align-items-center">
                          <div className="col-auto">
                            <i>
                              <FiUsers size={40} />
                            </i>
                          </div>
                          <div className="col">
                            <h3 className="f-w-300 d-flex">{data?.owners}</h3>

                            <span className="d-block text-uppercase d-flex">
                              TOTAL OWNERS
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="card-block">
                        <div className="row d-flex align-items-center">
                          <div className="col-auto">
                            <i>
                              <FiUsers size={40} />
                            </i>
                          </div>
                          <div className="col">
                            <h3 className="f-w-300 d-flex">{data?.agents}</h3>

                            <span className="d-block text-uppercase d-flex">
                              TOTAL AGENTS
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
