import React, { useEffect, useState } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import { FiEye, FiEdit } from "react-icons/fi";

const BalanceRequest = () => {
  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentItems, setCurrentItems] = useState([]);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 10;

  const fetchApiData = () => {
    axios.get("/admin/requests/balance").then((res) => {
      setData(res);
    });
  };

  useEffect(() => {
    fetchApiData();
  }, []);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(data?.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data?.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, data]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % data.length;
    setItemOffset(newOffset);
  };

  const updateStatus = (id) => {
    axios.patch("/admin/requests/balance", { id }).then((res) => {
      if (res) {
        fetchApiData();
      }
    });
  };

  return (
    <div className="pcoded-wrapper">
      <div className="pcoded-inner-content">
        <div className="main-body">
          <div className="page-wrapper">
            <div className="card">
              <div className="card-header d-flex justify-content-between bg-light">
                <h5 className="mt-2">Add Balance Requests</h5>
              </div>
              <div className="card-block table-border-style">
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>User Phone</th>
                        <th>User Balance</th>
                        <th>Amount</th>
                        <th>Service</th>
                        <th>Receipt</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentItems?.map((item, i) => (
                        <tr key={item?.id}>
                          <th scope="row">{i + 1}</th>
                          <td>{item?.user?.mobileNumber}</td>
                          <td>{item?.user?.balance}</td>
                          <td>{item.amount}</td>
                          <td>{item.type}</td>
                          <td>
                            <a
                              href={`https://api.vaguthun.com/${item.receipt}`}
                              target="_blank"
                            >
                              View
                            </a>
                          </td>
                          <td>
                            <button
                              onClick={() => updateStatus(item.id)}
                              className="btn btn-outline-primary"
                            >
                              Mark as Done
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ReactPaginate
        breakLabel="..."
        nextLabel=">"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="<"
        renderOnZeroPageCount={null}
        containerClassName="pagination"
        pageLinkClassName="page-num"
        previousLinkClassName="page-num"
        nextLinkClassName="page-num"
        activeClassName="active"
      />
    </div>
  );
};

export default BalanceRequest;
