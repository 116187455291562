import React from "react";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import axios from "axios";

const DeductBalance = ({ show, onHide, user }) => {
  const [amount, setAmount] = useState("");
  const updateStatus = (e) => {
    e.preventDefault();
    axios
      .patch("/admin/user/balance", { id: user.id, balance: amount })
      .then((res) => {
        onHide();
      });
  };
  return (
    <Modal show={show} onHide={onHide}>
      <div className="main-body ">
        <div className="page-wrapper">
          <div className="row ">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header _modalStyles bg-light">
                  <h5>Deduct Balance</h5>
                  <i
                    className="feather icon-x float-right c_pointer"
                    onClick={onHide}
                  ></i>
                </div>
                <div className="card-body">
                  <h5>{user?.mobileNumber}</h5>

                  <div className="row">
                    <div className="col-md-12">
                      <form onSubmit={updateStatus}>
                        <div className="form-group">
                          <label for="amount">Amount</label>
                          <input
                            type="number"
                            min={0}
                            placeholder="Enter Amount"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                            id="amount"
                            className="form-control"
                          />
                        </div>

                        <button
                          type="submit"
                          className="btn btn-primary mt-5 float-right"
                        >
                          Deduct Balance
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeductBalance;
