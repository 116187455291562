import "./App.css";
import "./assets/css/style.css";
import AdminDashboard from "./components/AdminDashboard";
import UserInfo from "./components/UserInfo";
import SideBar from "./components/SideBar";
// import Orders from "./components/Orders";
import { Route, Routes, Navigate } from "react-router-dom";
import SignIn from "./components/SignIn";
import { ToastContainer } from "react-toastify";
import Cookies from "universal-cookie";
import Requests from "./components/Requests";
import Schedule from "./components/Schedule";
import Medianet from "./components/Medianet";

// import AdminProfile from "./components/AdminProfile";
// import Categories from "./components/Categories";
// import { GrDashboard } from "react-icons/gr";
// import OwnersInfo from "./components/OwnersInfo";

function App() {
  const cookie = new Cookies();

  const user = cookie.get("user");

  return (
    <div className="app">
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* <SideBar />
      <Categories /> */}
      {user ? (
        <>
          <SideBar />
          <Routes>
            <Route path="/" element={<AdminDashboard />} />
            <Route path="/user_info" element={<UserInfo />} />
            <Route path="/requests" element={<Requests />} />
            <Route path="/schedule" element={<Schedule />} />
            <Route path="/medianet" element={<Medianet />} />
            {/* <Route path="/orders" element={<Orders />} />
            <Route path="/owners" element={<OwnersInfo />} />
            <Route path="/categories" element={<Categories />} />
            <Route path="/admin_profile" element={<AdminProfile />} /> */}
          </Routes>
        </>
      ) : (
        <Routes>
          <Route path="/login" element={<SignIn />} />
          <Route path="*" element={<Navigate to={"/login"} replace />} />
        </Routes>
      )}
    </div>
  );
}

export default App;
