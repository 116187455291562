import { useState } from "react";
import ScheduleRecharge from "./ScheduleRecharge";
import SchedulePackage from "./SchedulePackage";

const Schedule = () => {
  const [activeTab, setActiveTab] = useState("recharge");
  return (
    <>
      <div className="pcoded-main-container">
        <div className="pcoded-content">
          <div className="col-lg-12">
            <div className="d-flex mb-3 gap-3">
              <button
                onClick={() => setActiveTab("recharge")}
                className={
                  activeTab === "recharge"
                    ? "btn btn-primary"
                    : "btn btn-outline-primary"
                }
              >
                Schedule Recharge
              </button>
              <button
                onClick={() => setActiveTab("package")}
                className={
                  activeTab === "package"
                    ? "btn btn-primary"
                    : "btn btn-outline-primary"
                }
              >
                Schedule Package
              </button>
            </div>
            {activeTab === "recharge" && <ScheduleRecharge />}
            {activeTab === "package" && <SchedulePackage />}
          </div>
        </div>
      </div>
    </>
  );
};

export default Schedule;
