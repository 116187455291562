import React, { useEffect, useState } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import { FiEye, FiEdit } from "react-icons/fi";
import AddBalance from "./Modals/AddBalance";
import DeductBalance from "./DeductBalance";

const UserInfo = () => {
  const [data, setData] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [addBalance, setAddBalance] = useState(false);
  const [deductBalance, setDeductBalance] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [currentItems, setCurrentItems] = useState([]);
  const [itemOffset, setItemOffset] = useState(0);
  const [search, setSearch] = useState("");
  const itemsPerPage = 10;

  const fetchApiData = () => {
    axios.get("/admin/users").then((res) => {
      setData(res);
    });
  };

  useEffect(() => {
    if (!addBalance && !deductBalance) fetchApiData();
  }, [addBalance, deductBalance]);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(data?.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data?.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, data]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % data.length;
    setItemOffset(newOffset);
  };

  return (
    <>
      {addBalance && (
        <AddBalance
          user={currentUser}
          show={addBalance}
          onHide={() => setAddBalance(false)}
        />
      )}
      {deductBalance && (
        <DeductBalance
          user={currentUser}
          show={deductBalance}
          onHide={() => setDeductBalance(false)}
        />
      )}
      <div className="pcoded-main-container">
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <div className="main-body">
                <div className="page-wrapper">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-header d-flex justify-content-between bg-light">
                        <h5 className="mt-2">User</h5>
                        <div>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search..."
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="card-block table-border-style">
                        <div className="table-responsive">
                          <table className="table table-striped">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Phone Number</th>
                                <th>Balance</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {currentItems
                                ?.filter((c) => c.mobileNumber.includes(search))
                                ?.map((item, i) => (
                                  <tr key={item?.id}>
                                    <th scope="row">{i + 1}</th>
                                    <td>{item?.mobileNumber}</td>
                                    <td>{item.balance}</td>
                                    <td className="d-flex align-items-center">
                                      <button
                                        onClick={() => {
                                          setCurrentUser(item);
                                          setAddBalance(true);
                                        }}
                                        className="btn btn-outline-primary"
                                      >
                                        Add Balance
                                      </button>
                                      <button
                                        onClick={() => {
                                          setCurrentUser(item);
                                          setDeductBalance(true);
                                        }}
                                        className="btn btn-outline-primary"
                                      >
                                        Deduct Balance
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
            containerClassName="pagination"
            pageLinkClassName="page-num"
            previousLinkClassName="page-num"
            nextLinkClassName="page-num"
            activeClassName="active"
          />
        </div>
      </div>
    </>
  );
};

export default UserInfo;
