import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import "../src/assets/fonts/fontawesome/css/fontawesome-all.min.css";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import axios from "axios";
import { user } from "./cookies";

axios.defaults.baseURL = "https://api.vaguthun.com/v1";
// axios.defaults.baseURL = "http://localhost:5000/v1";
axios.defaults.headers.common["Authorization"] =
  "Bearer " + user?.tokens?.access?.token;

axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    if (response.data.message) {
      toast.success(response.data.message);
    }
    return response.data;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    toast.error(error?.response?.data?.message);
    // throw error;
  }
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);
